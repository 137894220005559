import { api } from "api";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchInput from "Utils/SearchInput";
import classNames from "classnames";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import { tipos_bodega, LATEX, SOLIDO } from "../../../../utility/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CardEmpty from "Utils/Cards/CardEmpty";

// const getFincas = (search) => {
//     return api
//         .get("finca", { search })
//         .then((data) => {
//             if (data) {
//                 return data.results;
//             }
//             return [];
//         })
//         .catch(() => {
//             return [];
//         });
// };

export default class Header extends Component {
    render() {
        const {
            search,
            to,
            textBtn,
            searchChange,
            enable_search,
            filtro,
            onChange,
            filtraDiaInicio,
            filtroDiaFinal,
            id_finca,
            fechaFinal,
            fechaInicio,
            fecha_inicio,
            fecha_final,
        } = this.props;


        return (
            <div className="d-flex justify-content-between mb-3 align-items-center">
                {enable_search !== undefined && (
                    <div>
                        <label className="mb-1 mt-1 mr-2">
                        Código
                        </label>
                        <SearchInput search={search} searchChange={searchChange} />
                    </div>
                )}
                <div className="d-flex flex-1 justify-content-end">
                    <div className="">
                        {to !== undefined && (
                            <Link to={to} className="btn btn-primary mb-3">
                                {textBtn}
                            </Link>
                        )}
                        {filtro !== undefined && (
                            <div className="col-12 col-lg-3">
                                <Select
                                    className={classNames(
                                        "react-select-container"
                                    )}
                                    backspaceRemovesValue={false}
                                    isSearchable={false}
                                    isClearable
                                    options={tipos_bodega}
                                    placeholder="Filtrar tipo"
                                    onChange={(e) => {
                                        onChange(e ? e : null);
                                    }}
                                    value={filtro}
                                />
                            </div>
                        )}

                        {filtraDiaInicio !== undefined && (
                            <div
                                className="col-12 col-lg-3 mt-3 mb-3 row"
                                style={{ borderRadius: "1em" }}
                            >
                                <div className="col-5 p-0 text-right">
                                    <label className="mb-1 mt-1 mr-2">
                                        Fecha inicio
                                    </label>
                                </div>
                                <div className="col-7 p-0">
                                    <DatePicker
                                        showPopperArrow={false}
                                        dateFormat="DD/MM/YYYY"
                                        placeholderText="Fecha de inicio"
                                        onChange={(value) => {
                                            fechaInicio(value);
                                        }}
                                        selected={fecha_inicio}
                                    />
                                </div>
                            </div>
                        )}
                        {filtroDiaFinal !== undefined && (
                            <div
                                className="col-12 col-lg-3 mt-3 mb-3 row"
                                style={{ borderRadius: "1em" }}
                            >
                                <div className="col-5 p-0 text-right">
                                    <label className="mb-1 mt-1 mr-2">
                                        Fecha final
                                    </label>
                                </div>
                                <div className="col-7 p-0">
                                    <DatePicker
                                        showPopperArrow={false}
                                        dateFormat="DD/MM/YYYY"
                                        placeholderText="Fecha final"
                                        onChange={(value) => {
                                            fechaFinal(value);
                                        }}
                                        selected={fecha_final}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        );
    }
}
